import { gql } from 'graphql-request';

export const ORGANIZATION_INFO = gql`
  query organizationInfo {
    organizationInfo {
      id
      name
      prefix
      language
      issuer
      idp
      integrationInfo {
        __typename
        ... on SmartSchoolInfo {
          smartSchoolDomain
        }
        ... on TeamsInfo {
          tenantId
        }
      }
      active
      selectedPeriodId
      periods {
        id
        name
        description
        startDate
        endDate
      }
      beta
      demo
      demoEndDate
      steams
    }
  }
`;
