import { FaCog } from 'react-icons/fa';
import { useSetRecoilState } from 'recoil';

import c from 'utils/functions/c';
import { sideBarAtom } from '../../utils/atom';

export default function QuestionBox({
  children,
  disabled,
  question,
  questionDisabled,
  setWarningDialogOpen,
  sideBarType,
  text,
  title,
}) {
  const setSideBar = useSetRecoilState(sideBarAtom);

  const onClick = () => {
    // Don't show the warning when the 'content' is published
    if (!disabled && questionDisabled) {
      setWarningDialogOpen(true);

      return;
    }

    setSideBar({
      type: sideBarType,
      id: question.id,
    });
  };

  return (
    <div
      className={c(
        'pt-2 px-4 pb-16 rounded flex-grow bg-gray-100 cursor-pointer overflow-y-auto h-96',
        questionDisabled && 'opacity-50',
      )}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <div className="text-gray-800 font-bold">{title}</div>
        <div>
          <FaCog className="text-gray-500 transition-colors hover:text-gray-800 w-3 h-3" />
        </div>
      </div>
      {text && <div className="text-gray-800">{text}</div>}
      <div
        className={c('mt-2 w-full', questionDisabled && 'pointer-events-none')}
      >
        {children}
      </div>
    </div>
  );
}
