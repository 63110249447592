import { useContext, useEffect, useRef } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import URLS from 'utils/constants/urls';
import { LanguageContext } from 'providers/LanguageProvider';
import useFormatMessage from '../../hooks/useFormatMessage';
import useParams from '../../hooks/useParams';

import { FIND_SCRATCH_EXERCISE_VERSION } from '../../api/exercise';

export default function ExerciseVersionContainer() {
  const t = useFormatMessage();
  const navigate = useNavigate();

  const { versionId } = useParams();
  const { language } = useContext(LanguageContext);

  const { data, error, loading } = useQuery(FIND_SCRATCH_EXERCISE_VERSION, {
    variables: {
      versionId: Number(versionId),
      language: language.toUpperCase(),
    },
  });

  const hasError = useRef();

  useEffect(() => {
    if (!loading) {
      if (error && error.message?.includes('Exception: ForbiddenException')) {
        // Ref is necessary to keep the variable outside of the useEffect hook
        hasError.current = true;
      } else {
        navigate(
          generatePath(URLS.EXERCISE_ID_VERSION_ID, {
            id: data?.findScratchExerciseVersion?.exerciseId,
            versionId,
          }),

          // `${EXERCISE}/${data?.findScratchExerciseVersion?.exerciseId}`,
        );
      }
    }
  }, [data, loading, error, navigate, versionId]);

  if (hasError.current) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        {t('exercise.forbidden_error')}
      </div>
    );
  }

  return null;
}
