import { InteractionStatus } from '@azure/msal-browser';

import { clearLocalStorage } from './clearLocalStorage';

export function handleRedirectAndLocalStorage(account, inProgress, instance) {
  if (!account && inProgress === InteractionStatus.None) {
    // clear local storage the hard way
    clearLocalStorage();

    instance.loginRedirect().catch(() => instance.handleRedirectPromise());
  }
}
