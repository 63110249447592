import { FaPlus } from 'react-icons/fa';

import c from 'utils/functions/c';
import useFormatMessage from '../../../../../hooks/useFormatMessage';
import useMultipleChoiceQuestion from '../../../hooks/useMultipleChoiceQuestion';

import { SIDE_BARS } from '../../SideBars/SideBarContextProvider';
import QuestionBox from '../QuestionBox';

import QuestionAnswerMultipleChoice from './QuestionAnswerMultipleChoice';

const UPPER_THRESHOLD_ANSWERS = 15;

export function MultipleChoice({
  disabled,
  questionDisabled,
  questionId,
  setWarningDialogOpen,
}) {
  const t = useFormatMessage();

  const { createQuestionAnswerMultipleChoice, question } =
    useMultipleChoiceQuestion(questionId);

  if (!question) {
    return null;
  }

  const answers = question.questionAnswersMultipleChoice;

  return (
    <div className="flex flex-col flex-grow border-t border-gray-300 p-2 overflow-y-auto">
      <QuestionBox
        disabled={disabled}
        question={question}
        questionDisabled={questionDisabled}
        setWarningDialogOpen={setWarningDialogOpen}
        sideBarType={SIDE_BARS.MULTIPLE_CHOICE_QUESTION_SIDEBAR}
        title={t('global.answer')}
      >
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
          {answers.map((answer, index) => (
            <QuestionAnswerMultipleChoice
              key={answer.id}
              index={index}
              questionAnswerMultipleChoiceId={answer.id}
              questionId={questionId}
            />
          ))}
          {answers.length !== UPPER_THRESHOLD_ANSWERS &&
            !question.publishedAt && (
              <button
                className={c(
                  'rounded border-dashed border-gray-400 border-2 text-2xl text-gray-400 cursor-pointer flex items-center justify-center h-full min-h-16',
                  !disabled && 'hover:bg-gray-200',
                  disabled && 'opacity-50',
                )}
                disabled={disabled}
                onClick={() =>
                  createQuestionAnswerMultipleChoice({
                    value: '',
                    correct: false,
                    explanation: '',
                  })
                }
                type="button"
              >
                <FaPlus />
              </button>
            )}
        </div>
      </QuestionBox>
    </div>
  );
}
